
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import {Prop} from 'vue-property-decorator';

    import {
        IOrganization,
    } from '@/types';

    @Component<OrganizationNewsCard>({
        components: {
        },
    })
    export default class OrganizationNewsCard extends Vue {
        public showMap: boolean =  false;

        @Prop({
            type: Object,
            required: false,
        })
        public organization!: IOrganization;
    }
